import styled from '@emotion/styled';
import { Skeleton, TableV2 } from 'src/components-dummy';
import { SynonymStatus } from 'src/services/src/service/augmented-search/synonyms/types';

export const StyledCellText = styled.span<{ backgroundColorToValue: string; isDeleted: boolean }>`
  background-color: ${({ theme, isDeleted, backgroundColorToValue }) => {
    const deletedColor = theme.palette.custom['gray-60'];
    const defaultColor =
      backgroundColorToValue === SynonymStatus.Indexed
        ? theme.palette.custom.lightGreen
        : backgroundColorToValue === SynonymStatus.PendingIndex
          ? theme.palette.custom.lightYellow
          : theme.palette.custom.lightPurple;

    return isDeleted ? deletedColor : defaultColor;
  }};
  padding: 2px 8px;
  gap: 10px;
  border-radius: 4px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;

  @media (max-height: 900px) {
    margin-bottom: 20px;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const FiltersRowWrapperStyled = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
`;

export const SkeletonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  gap: 15px;
  padding: 0 6px;
`;

export const StyledTooltipWrapper = styled.div`
  .syte-popup-trigger {
    display: inline-block;
  }
  width: 100%;
`;

export const BodyRowStyled = styled(TableV2.BodyRow)<{ isDeleted: boolean }>`
  position: relative;

  ${({ isDeleted, theme }) =>
    isDeleted &&
    `
      color: ${theme.palette.custom['gray-30']};
      background-color: ${theme.palette.custom['gray-90']};
    `}
`;

export const StrikeThroughStyled = styled.div`
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: ${({ theme }) => theme.palette.custom['gray-20']} solid 1px;
  }
`;

export const MissingAugmentedSearchCatalogCardWrapperStyled = styled.div`
  margin-top: 42px;
`;

export const TableWrapperStyled = styled.div`
  flex: 1;
  overflow-y: auto;
`;
