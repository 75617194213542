import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { visualEditorActions, visualEditorFetchOffersActions } from '../state';
import { useDebounce } from '../../../components-dummy';
import { useVisualEditorProvider } from '../context';
import { useGetDataFieldsToDisplay } from './useGetDataFieldsToDisplay';
import { AsyncThunkPromise } from '../../../types';
import { BrowsePlpPayload, IndexedProductOffers } from '../types';

export const useFetchPLPOffers = (shopId: number, locale: string) => {
  const { categoryFilterRule } = useAppSelector(state => state.visualEditor);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(visualEditorActions.getCategoryFilterRule({ shopId }));
  }, [shopId]);

  const { dispatchOffersThunk } = useVisualEditorProvider();
  const thunkPromise = useRef<AsyncThunkPromise<IndexedProductOffers, BrowsePlpPayload> | null>(
    null
  );

  const [categoryValue, setCategoryValue] = useState<string>('');

  const { fields } = useGetDataFieldsToDisplay({ shopId });

  const fetchPlpOffers = useCallback(
    (payload: BrowsePlpPayload) => {
      thunkPromise.current = dispatchOffersThunk(
        visualEditorFetchOffersActions.getBrowsePLPItems,
        payload
      );
    },
    [dispatchOffersThunk]
  );

  const fetchOffersDebounced = useDebounce(fetchPlpOffers, 300);

  const updateCategoryValue = useCallback(
    (searchValue: string) => {
      const searchValueTrimmed = searchValue.trim();

      if (searchValueTrimmed.length < 2) {
        fetchOffersDebounced.cancel();
        thunkPromise.current?.abort();
        setCategoryValue('');
        return;
      }
      if (categoryFilterRule) {
        const { field, operator } = categoryFilterRule;

        fetchOffersDebounced({
          shopId,
          field,
          operator,
          value: searchValueTrimmed,
          fields,
          colorVariantFields: fields,
          sizeVariantFields: fields,
          locale,
        });
      }

      setCategoryValue(searchValue);
    },
    [shopId, fetchOffersDebounced, fields, categoryFilterRule, locale]
  );

  useEffect(() => {
    if (categoryFilterRule) {
      updateCategoryValue(categoryValue);
    }
  }, [fields, categoryFilterRule]);

  return {
    updateCategoryValue,
  };
};
