import React, { MouseEvent } from 'react';
import { AvailableIcons, Tooltip } from '../../../../components-dummy';
import { getLocaleDisplayNameWithEmoji } from './get-locale-display-name-with-emoji';
import {
  LayoutViewButton,
  StyledIcon,
} from '../VisualEditorResultsScreen/VisualEditorResultsScreen.styles';

interface ButtonProps {
  locales: string[];
  locale: string;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
}

export const Button = ({ locales, locale, onMenuClicked }: ButtonProps) => {
  const isSingleLocale = locales.length === 0;

  const { displayName, locale: matchedLocale } = getLocaleDisplayNameWithEmoji(locale);
  const [icon] = displayName.split(' ');

  const button = (
    <LayoutViewButton variant='tertiary' disabled={isSingleLocale} onClick={onMenuClicked}>
      {isSingleLocale ? <StyledIcon name={AvailableIcons.Globe} /> : icon}
    </LayoutViewButton>
  );

  return <Tooltip value={`${displayName}, ${matchedLocale}`}>{button}</Tooltip>;
};
