import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationType, Page } from 'src/components-dummy';
import {
  ICatalogProduct,
  IShopCatalog,
} from 'src/services/src/service/types/catalog-manager/catalog-manager';
import {} from 'src/components-bl/CatalogManager/CatalogManager.styles';
import { Dispatch } from 'src/components-bl/types';
import { PageStyled } from './CatalogExplorerPage.styles';
import { CatalogExplorerPageActions } from './CatalogExplorerPage.actions';
import { CatalogExplorerProductsView } from '../CatalogExplorerProductsView/CatalogExplorerProductsView';
import { ShopDataField } from 'src/services';
import {
  DEFAULT_RESULTS_PER_PAGE_ENUM,
  OnPaginationChange,
} from 'src/components-dummy/PaginationToolBar';
import { CatalogExplorerPageHeader } from '../CatalogExplorerPageHeader/CatalogExplorerPageHeader';
import { MetaLanguage } from 'src/app-state-types';
import { isAbortError } from 'src/utils/error-helpers';
import { usePreviousState } from 'src/hooks';
import { debounce } from 'lodash';
import { CatalogExplorerFilters } from '../CatalogExplorerFilters/CatalogExplorerFilters';
import { DataFieldsByType, DisplayFieldsByType } from '../types';

export interface CatalogExplorerPageProps {
  catalog?: IShopCatalog;
  catalogProducts?: ICatalogProduct[];
  totalCatalogProducts?: number;
  totalFilteredProducts?: number;
  shopDataFields?: ShopDataField[];
  catalogName: string;
  shopId: number;
  locale: string;
  languageMetadata?: MetaLanguage;
  dataFieldsByType: DataFieldsByType;
  displayFieldsByType: DisplayFieldsByType;
  isWaitingForDataFields: boolean;
  isMerchRulesAITagsEnabled: boolean;
  hasCustomLexiconConfig: boolean;
  onBackButtonCLick: () => void;
  dispatch: Dispatch;
}

interface FiltersAndPaginationState {
  pagination: {
    skip: number;
    limit: number;
    totalItems: number;
  };
  filters: Record<string, string[] | number[]>;
  mainSearchFilterTerm?: string;
}

export const CatalogExplorerPage = ({
  catalog,
  catalogProducts,
  totalCatalogProducts,
  totalFilteredProducts,
  catalogName,
  shopId,
  locale,
  languageMetadata,
  dataFieldsByType,
  displayFieldsByType,
  isWaitingForDataFields,
  isMerchRulesAITagsEnabled,
  hasCustomLexiconConfig,
  onBackButtonCLick,
  dispatch,
}: CatalogExplorerPageProps): JSX.Element => {
  const getProductsCancellationRef = useRef(null as AbortController | null);

  const prevDisplayPreferences = usePreviousState(displayFieldsByType);

  const [filtersAndPagination, setFiltersAndPagination] = useState<FiltersAndPaginationState>({
    filters: {},
    pagination: {
      skip: 0,
      totalItems: catalogProducts?.length || 0,
      limit: DEFAULT_RESULTS_PER_PAGE_ENUM[10] as number,
    },
    mainSearchFilterTerm: '',
  });

  const [isPendingOnFetchProducts, setIsPendingOnFetchProducts] = useState(false);

  const cancelGetProductsSignal = () => {
    if (!getProductsCancellationRef.current) {
      return;
    }

    if (getProductsCancellationRef.current.signal.aborted) {
      return;
    }

    try {
      getProductsCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      getProductsCancellationRef.current = null;
    }
  };

  const getProducts = useCallback(async () => {
    if (isWaitingForDataFields) {
      return;
    }

    const dataFieldsToInclude = [
      ...displayFieldsByType.catalog_field.map(item => item.value),
      ...displayFieldsByType.ai_tag.map(item => item.value),
    ];

    if (!dataFieldsToInclude.length) {
      return;
    }

    setIsPendingOnFetchProducts(true);

    cancelGetProductsSignal();

    getProductsCancellationRef.current = new AbortController();

    let aborted = false;

    const cleanedFilters = Object.keys(filtersAndPagination.filters).reduce((prev, filterKey) => {
      const filterValues = filtersAndPagination.filters[filterKey];

      if (filterValues.length > 0) {
        prev[filterKey] = filterValues;
      }

      return prev;
    }, {});

    const thunkPromise: any = dispatch(
      CatalogExplorerPageActions.getProducts({
        shopId,
        catalogName,
        locale,
        dataFields: dataFieldsToInclude,
        pagination: {
          skip: filtersAndPagination.pagination.skip,
          limit: filtersAndPagination.pagination.limit,
        },
        filters: cleanedFilters,
        mainSearchFilterTerm: filtersAndPagination.mainSearchFilterTerm,
        cancellationSignal: getProductsCancellationRef.current.signal,
      })
    ) as any;

    thunkPromise
      .unwrap()
      .catch((apiError: any) => {
        aborted = isAbortError(apiError.error);

        if (!aborted) {
          console.error(apiError);
          dispatch(
            CatalogExplorerPageActions.notification({
              type: NotificationType.Error,
              customMessage: 'Failed to fetch catalog products',
            })
          );
        }
      })
      .finally(() => {
        if (!aborted || prevDisplayPreferences !== displayFieldsByType) {
          setIsPendingOnFetchProducts(false);
        }
      });
  }, [
    filtersAndPagination.pagination.skip,
    filtersAndPagination.pagination.limit,
    filtersAndPagination.filters,
    filtersAndPagination.mainSearchFilterTerm,
    displayFieldsByType,
    catalog?.isMultiLocale,
    shopId,
    catalogName,
    isWaitingForDataFields,
  ]);

  const debouncedGetProducts = useCallback(debounce(getProducts, 300), [getProducts]);

  useEffect(() => {
    if (!shopId || !catalogName || isWaitingForDataFields) {
      return;
    }

    debouncedGetProducts();

    return () => {
      debouncedGetProducts.cancel();
    };
  }, [catalogName, getProducts, shopId, isWaitingForDataFields]);

  useEffect(() => {
    return () => {
      cancelGetProductsSignal();
      dispatch(CatalogExplorerPageActions.clearCatalogExplorerData());
    };
  }, []);

  const onPaginationChange: OnPaginationChange = useCallback(newState => {
    setFiltersAndPagination(prevState => {
      if (
        prevState.pagination.skip !== newState.skip ||
        prevState.pagination.limit !== newState.limit
      ) {
        setIsPendingOnFetchProducts(true);
      }

      return {
        ...prevState,
        pagination: { ...prevState.pagination, ...newState },
      };
    });
  }, []);

  /**
   * Dynamic filters by field name.
   */
  const onFiltersChange = useCallback(
    (partialUpdate: Record<string, string[] | number[]>): void => {
      setFiltersAndPagination(prevState => {
        const hasChanges = Object.keys(partialUpdate).some(filterKey => {
          const updateFilterValues = partialUpdate[filterKey];
          const prevFilterValues = prevState.filters[filterKey] || [];

          return updateFilterValues !== prevFilterValues;
        });

        if (hasChanges) {
          return {
            ...prevState,
            pagination: { ...prevState.pagination, skip: 0 },
            filters: { ...prevState.filters, ...partialUpdate },
          };
        }

        return prevState;
      });
    },
    []
  );

  const onMainSearchFilterChange = useCallback(
    debounce((value: string) => {
      setFiltersAndPagination(prevState => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            skip: 0,
          },
          mainSearchFilterTerm: value,
        };
      });
    }, 350),
    []
  );

  const onClearAllFilters = useCallback(() => {
    setFiltersAndPagination(prevState => {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          skip: 0,
        },
        filters: {},
        mainSearchFilterTerm: '',
      };
    });
  }, []);

  const onExportToCSV = useCallback(() => {
    dispatch(
      CatalogExplorerPageActions.notification({
        type: NotificationType.Success,
        customMessage:
          'Your download is in progress. Due to the file size, this may take some time. Thank you for your patience.',
      })
    );

    return dispatch(
      CatalogExplorerPageActions.downloadIndexedFeedFile({
        shopId,
        catalogName,
      })
    ) as any;
  }, [shopId, catalogName]);

  const displayAllFieldsMap = useMemo(() => {
    if (!displayFieldsByType) return {};
    return [...displayFieldsByType.ai_tag, ...displayFieldsByType.catalog_field].reduce(
      (prev, next) => {
        prev[next.value] = next;
        return prev;
      },
      {}
    );
  }, [displayFieldsByType]);

  useEffect(() => {
    setFiltersAndPagination(prevState => {
      let someFilterRemoved = false;

      const updatedFilters = Object.keys(prevState.filters).reduce((prev, filterKey) => {
        const isExistsInDisplayList = !!displayAllFieldsMap[filterKey];

        // Filter removed fields filters (new displayed fields list)
        if (isExistsInDisplayList) {
          prev[filterKey] = prevState.filters[filterKey];
        } else if (prevState.filters[filterKey].length > 0) {
          someFilterRemoved = true;
        }

        return prev;
      }, {});

      return {
        ...prevState,
        // Reset skip incase filters have been changed
        pagination: someFilterRemoved ? { ...prevState.pagination, skip: 0 } : prevState.pagination,
        filters: updatedFilters,
      };
    });
  }, [displayAllFieldsMap]);

  return (
    <PageStyled>
      <Page.Header>
        <CatalogExplorerPageHeader
          catalogName={catalogName}
          catalog={catalog}
          totalCatalogProducts={totalCatalogProducts}
          languageMetadata={languageMetadata}
          shopId={shopId}
          locale={locale}
          dataFieldsByType={dataFieldsByType}
          displayFieldsPreferencesByType={displayFieldsByType}
          isMerchRulesAITagsEnabled={isMerchRulesAITagsEnabled}
          hasCustomLexiconConfig={hasCustomLexiconConfig}
          onBackButtonCLick={onBackButtonCLick}
          onExportToCSV={onExportToCSV}
          dispatch={dispatch}
        />
      </Page.Header>
      <Page.Content>
        <CatalogExplorerFilters
          onFiltersStateChange={onFiltersChange}
          onMainSearchFilterChange={onMainSearchFilterChange}
          onClearAllFilters={onClearAllFilters}
          shopId={shopId}
          catalogName={catalogName}
          displayFieldsMap={displayAllFieldsMap}
          filters={filtersAndPagination.filters}
          mainSearchFilterTerm={filtersAndPagination.mainSearchFilterTerm}
          totalFilteredProducts={totalFilteredProducts || 0}
          locale={locale}
          dispatch={dispatch}
        />
        <CatalogExplorerProductsView
          catalogProducts={catalogProducts}
          totalCatalogProducts={totalFilteredProducts}
          shopId={shopId}
          isPendingOnFetchProducts={isPendingOnFetchProducts}
          displayFieldsMap={displayAllFieldsMap}
          displayFieldsPreferencesByType={displayFieldsByType}
          paginationState={filtersAndPagination.pagination}
          isLoading={isWaitingForDataFields}
          onPaginationChange={onPaginationChange}
        />
      </Page.Content>
    </PageStyled>
  );
};
