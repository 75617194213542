import React from 'react';
import {
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { useFetchTextSearchOffers } from './useFetchTextSearchOffers';
import { FormGroup } from '../Form';
import { FlexContainer } from '../VisualEditor.styles';
import { PermittedRouteMap } from '../../../../app-routes';
import { PreviewFeature } from '../../../../services';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';

interface TextSearchProps {
  shopId: number;
  stateController: StateController<string>;
  permittedRouteMap: PermittedRouteMap;
  selectedExperience?: PreviewFeature;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
  locale: string;
}

export const TextSearch = ({
  shopId,
  stateController,
  permittedRouteMap,
  selectedExperience,
  resultsScreenRef,
  locale,
}: TextSearchProps) => {
  const { value, onValueChange } = useFetchTextSearchOffers({
    shopId,
    stateController,
    permittedRouteMap,
    selectedExperience,
    resultsScreenRef,
    locale,
  });

  return (
    <>
      <FormGroup>
        <FlexContainer>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            When shopper is searching for
          </Typography>
        </FlexContainer>
        <TextBox value={value} onChange={onValueChange} placeholder='Enter search term' />
      </FormGroup>
    </>
  );
};
