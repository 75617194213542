import React, { useCallback } from 'react';
import { FormGroup } from '../Form';
import {
  AutoCompleteSingleValue,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { Option } from '../AutoComplete';
import { useBrowsePlpSettings } from './useBrowsePlpSettings';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { useFetchAutoSuggestResults } from './useFetchAutoSuggestResults';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { PreviewFeature } from '../../../../services';
import { PermittedRouteMap } from '../../../../app-routes';
import { CategoryFilterRule } from '../../types/category-filter-rule';
import { FlexContainer } from '../VisualEditor.styles';

interface Props {
  shopId: number;
  stateController: StateController<string>;
  selectedExperience?: PreviewFeature;
  permittedRouteMap: PermittedRouteMap;
  categoryFilterRule: CategoryFilterRule;
  locale: string;
}

export function BrowsePlpSettings({
  shopId,
  stateController,
  selectedExperience,
  permittedRouteMap,
  categoryFilterRule,
  locale,
}: Props): JSX.Element {
  const { category, changeCategory } = useBrowsePlpSettings({
    shopId,
    stateController,
    permittedRouteMap,
    locale,
    selectedExperience,
  });

  const { options, onChange, onSelectOption, loading } = useFetchAutoSuggestResults({
    shopId,
    categoryFilterRule,
    changeCategory,
  });

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorEditCategoryPage,
  });

  const editCategoryPage = useCallback(() => {
    navigateToVisualEditorRoute({ shopId });
  }, [navigateToVisualEditorRoute, shopId]);

  return (
    <FormGroup>
      <FlexContainer>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Category page is
        </Typography>
        <ExternalLink onClick={editCategoryPage}>Edit Category Filter</ExternalLink>
      </FlexContainer>
      <AutoCompleteSingleValue
        placeholder='Select category page'
        selectedValue={category}
        onChange={onChange}
        options={options}
        onSelectOption={onSelectOption}
        openPopUp
        loading={loading}
      >
        <AutoCompleteSingleValue.Option>
          {props => <Option {...props} displayOptionTitleOnly />}
        </AutoCompleteSingleValue.Option>
      </AutoCompleteSingleValue>
    </FormGroup>
  );
}
