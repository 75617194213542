import React, { useCallback, useMemo } from 'react';
import { Page, TypographyType, TypographyVariant } from 'src/components-dummy';
import { IShopCatalog } from 'src/services/src/service/types/catalog-manager/catalog-manager';
import { TitleMainStyled } from 'src/components-bl/CatalogManager/CatalogManager.styles';
import { Dispatch } from 'src/components-bl/types';
import { cardColors, LanguageCard } from 'src/components-dummy/LanguageCard';
import { MetaLanguage } from 'src/app-state-types';
import { PageStyled } from '../CatalogExplorerPage/CatalogExplorerPage.styles';
import { LanguageListStyled, LanguagesTitleStyled } from './CatalogExplorerLanguages.styles';
import { CatalogExplorerLanguagesActions } from './CatalogExplorerLanguages.actions';
import { generatePath } from 'react-router';

export interface CatalogExplorerLanguagesPageProps {
  catalog?: IShopCatalog;
  shopId: number;
  languages: Record<string, MetaLanguage>;
  permittedRouteMap: any;
  dispatch: Dispatch;
}

export const CatalogExplorerLanguagesPage = ({
  catalog,
  shopId,
  languages,
  permittedRouteMap,
  dispatch,
}: CatalogExplorerLanguagesPageProps): JSX.Element => {
  const onCardClick = useCallback(
    (locale: string) => {
      if (permittedRouteMap.catalogExplorer && locale && shopId && catalog) {
        dispatch(
          CatalogExplorerLanguagesActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.catalogExplorer.path, {
              shopId,
              catalogName: catalog.name,
              locale,
            }),
          })
        );
      }
    },
    [dispatch, shopId, catalog, permittedRouteMap.catalogExplorer]
  );

  const getCardColor = (index: number) => {
    const color = cardColors[index % cardColors.length];
    return color;
  };

  const languageCards = useMemo(() => {
    return Object.keys(languages).map((languageKey, index) => {
      const language = languages[languageKey];
      const { iconName, displayName } = language;

      if (!iconName || !displayName) return null;

      return (
        <LanguageCard
          key={language.locale}
          displayName={displayName}
          iconName={iconName}
          color={getCardColor(index)}
          onClick={() => onCardClick(language.locale)}
        />
      );
    });
  }, [languages]);

  return (
    <PageStyled>
      <Page.Header>
        <TitleMainStyled
          type={TypographyType.Heading}
          variant={TypographyVariant.ExtraSmallBold}
          className='syte-page-title'
        >
          Catalog Explorer
        </TitleMainStyled>
      </Page.Header>
      <Page.Content>
        <LanguagesTitleStyled>I want to organize the language:</LanguagesTitleStyled>
        <LanguageListStyled>{languageCards}</LanguageListStyled>
      </Page.Content>
    </PageStyled>
  );
};
