import Joi, { AnySchema } from 'joi';
import {
  MAX_SYNONYM_WORD_CHARACTER_COUNT,
  MAX_TERMS_IN_SYNONYM,
  MIN_SYNONYM_WORD_CHARACTER_COUNT,
  MIN_TERMS_IN_SYNONYM,
} from '../../constants';

const phraseWordValidationSchema = Joi.string()
  .min(MIN_SYNONYM_WORD_CHARACTER_COUNT)
  .max(MAX_SYNONYM_WORD_CHARACTER_COUNT)
  .messages({
    'string.min': `A word must contain at least ${MIN_SYNONYM_WORD_CHARACTER_COUNT} characters`,
    'string.max': `A word must contain at most ${MAX_SYNONYM_WORD_CHARACTER_COUNT} characters`,
  });

export const synonymValidationSchema: { phrase: AnySchema } = {
  phrase: Joi.array()
    .items(phraseWordValidationSchema)
    .min(MIN_TERMS_IN_SYNONYM)
    .max(MAX_TERMS_IN_SYNONYM)
    .required()
    .messages({
      'array.min': `At least ${MIN_TERMS_IN_SYNONYM} words are required`,
      'array.max': `The maximum number of words is ${MAX_TERMS_IN_SYNONYM}`,
    }),
};
