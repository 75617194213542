import { useCallback, useEffect, useRef } from 'react';
import { visualEditorActions, visualEditorFetchOffersActions } from '../../state';
import { ContentType, SimilarItemsPayload } from '../../types';
import { useAppSelector } from '../../../../hooks';
import { useGetDataFieldsToDisplay } from '../../hooks';
import { useVisualEditorProvider } from '../../context';
import { useDebounce } from '../../../../components-dummy';

export const useSimilarItemsSettings = (shopId: number, locale: string) => {
  const { selectedOffer, offers } = useAppSelector(({ visualEditor }) => visualEditor);
  const { appDispatch, dispatchOffersThunk } = useVisualEditorProvider();
  const { sku } = selectedOffer || {};
  const thunkPromiseRef = useRef(null as { abort: () => void } | null);

  const { fields } = useGetDataFieldsToDisplay({ shopId });

  const fetchSimilarsOffers = useCallback((payload: SimilarItemsPayload) => {
    const thunkPromise: any = dispatchOffersThunk(
      visualEditorFetchOffersActions.getSimilarItems,
      payload
    );
    thunkPromiseRef.current = thunkPromise;
  }, []);

  const fetchOffersDebounced = useDebounce(fetchSimilarsOffers, 300);

  /* Actions on offer removed */
  useEffect(() => {
    if (selectedOffer || !offers.length) return;
    appDispatch(visualEditorActions.setContentType(ContentType.SelectExperience));
  }, [selectedOffer]);

  useEffect(() => {
    if (!selectedOffer) return;
    fetchOffersDebounced({
      shopId,
      sku,
      fields,
      colorVariantFields: fields,
      sizeVariantFields: fields,
      locale,
    });
  }, [selectedOffer, shopId, sku, fields, locale, fetchOffersDebounced]);
};
