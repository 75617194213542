import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { catalogExplorerContainerActions } from '../CatalogExplorerContainer';
import { useAppSelector } from 'src/hooks';
import { shallowEqual, useDispatch } from 'react-redux';
import { IShopCatalog } from 'src/services';
import { CatalogExplorerLanguagesPage } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerLanguagesPage/CatalogExplorerLanguagesPage';
import { RoutedComponentProps } from 'src/app-routes';
import { MetaLanguage } from 'src/app-state-types';

type CatalogExplorerLanguagesContainerProps = RoutedComponentProps;

export const CatalogExplorerLanguagesContainer = ({
  permittedRouteMap,
}: CatalogExplorerLanguagesContainerProps): JSX.Element | null => {
  const { catalogName } = useParams<{ catalogName: string }>();

  const dispatch = useDispatch();

  const { catalogs, shopId, languages } =
    useAppSelector(state => {
      return {
        catalogs: state.catalogManager.catalogs,
        shopId: state.shop.current.shopId,
        languages: state.meta.languages,
      };
    }, shallowEqual) || {};

  const selectedCatalog: IShopCatalog | undefined = useMemo(() => {
    return catalogs?.find(catalog => catalog.name === catalogName);
  }, [catalogs]);

  const languagesMap: Record<string, MetaLanguage> = useMemo(() => {
    if (!selectedCatalog) return {};

    const catalogLocalesSet = new Set([...selectedCatalog.validLocales]);

    return languages.reduce((prev, next) => {
      if (!catalogLocalesSet.has(next.locale)) {
        return prev;
      }

      prev[next.locale] = next;
      return prev;
    }, {});
  }, [languages, selectedCatalog]);

  const navigateToCatalogsManagerList = useCallback(() => {
    if (permittedRouteMap.catalogManager?.path && shopId) {
      const path = generatePath(permittedRouteMap.catalogManager?.path, { shopId });

      dispatch(
        catalogExplorerContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  }, [permittedRouteMap.catalogManager?.path, shopId]);

  useEffect(() => {
    const catalogExistsForShop = catalogs?.some(catalog => catalog.name == catalogName);

    if (catalogs && !catalogExistsForShop) {
      console.warn(`Catalog - ${catalogName} doesn't exist in shop`);
      navigateToCatalogsManagerList();
    }
  }, [catalogs, catalogName]);

  if (!shopId) {
    return null;
  }

  useEffect(() => {
    if (selectedCatalog && !selectedCatalog.isMultiLocale) {
      navigateToCatalogsManagerList();
    }
  }, [selectedCatalog]);

  return (
    <CatalogExplorerLanguagesPage
      shopId={shopId}
      catalog={selectedCatalog}
      languages={languagesMap}
      permittedRouteMap={permittedRouteMap}
      dispatch={dispatch}
    />
  );
};
