import styled from '@emotion/styled';

export const SynonymsTitleStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px !important;
`;

export const SynonymsContainerStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageTitlesWrapper = styled.div`
  gap: 8px;
`;
