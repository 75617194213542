import styled from '@emotion/styled';
import { Icon, Menu, Typography } from 'src/components-dummy';

export const SynonymsPageMainHeaderTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlagIcon = styled(Icon)`
  width: 40px;
  padding: 0 10px;
`;

export const SynonymsPageHeaderTitleLanguage = styled.span`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  display: flex;
  cursor: pointer;
`;

export const CaretIconStyled = styled(Icon)<{ shouldRotate: boolean }>`
  stroke: ${({ theme }) => theme.palette.custom['primary-main']};
  ${({ shouldRotate }) => shouldRotate && 'transform: rotate(180deg);'}
`;

export const DropdownMenuStyled = styled(Menu)`
  .MuiPaper-root {
    max-height: unset;
    width: 150px;
  }
`;

export const MenuItemTextStyled = styled(Typography)`
  display: flex;
  align-items: center;
`;
