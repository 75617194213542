import React from 'react';
import {
  AvailableIcons,
  Tooltip,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { Locale } from '../Locale/Locale';
import { VisualEditorSettings } from './VisualEditorSettings/VisualEditorSettings';
import { Layout } from '../../consts/layout';
import {
  ItemsCount,
  LayoutSwitchContainer,
  LayoutViewButton,
  RefreshButton,
  StyledIcon,
  ToolBarContainer,
  ToolbarContainer,
  VisualEditorTitleContainer,
} from './VisualEditorResultsScreen.styles';

interface Props {
  shopId: number;
  onRefresh?: () => void;
  disabled: boolean;
  itemsCount: number;
  totalItemsCount: number;
  layout: Layout;
  onLayoutChange: (view: Layout) => void;
  shouldDisplayItemsCount: boolean;
  shouldDisplaySettings: boolean;
  locales: string[];
  defaultLocale: string;
  locale: string;
}

export function VisualEditorResultsToolbar({
  shopId,
  onRefresh,
  itemsCount,
  totalItemsCount,
  disabled,
  layout,
  onLayoutChange,
  shouldDisplayItemsCount,
  shouldDisplaySettings,
  locales,
  defaultLocale,
  locale,
}: Props): JSX.Element {
  return (
    <VisualEditorTitleContainer>
      <ItemsCount
        type={TypographyType.Body}
        variant={TypographyVariant.MediumMedium}
        shouldDisplayItemsCount={shouldDisplayItemsCount}
      >
        Displaying {itemsCount} out {totalItemsCount} results
      </ItemsCount>
      <ToolbarContainer>
        <RefreshButton
          startIcon={AvailableIcons.ResetArrow}
          onClick={onRefresh}
          disabled={disabled}
        >
          Refresh results
        </RefreshButton>
        <ToolBarContainer>
          <LayoutSwitchContainer>
            <Tooltip value='Grid view'>
              <LayoutViewButton
                variant='tertiary'
                active={layout === Layout.GRID}
                onClick={() => onLayoutChange(Layout.GRID)}
              >
                <StyledIcon name={AvailableIcons.Dashboard} />
              </LayoutViewButton>
            </Tooltip>
            <Tooltip value='List view'>
              <LayoutViewButton
                variant='tertiary'
                active={layout === Layout.LIST}
                onClick={() => onLayoutChange(Layout.LIST)}
              >
                <StyledIcon name={AvailableIcons.ListView} />
              </LayoutViewButton>
            </Tooltip>
          </LayoutSwitchContainer>
          <StyledIcon
            name={AvailableIcons.QuestionCircle}
            onClick={() =>
              window.open('https://support.syte.ai/space/ET/3091038214/Visual+Editor', '_blank')
            }
          />
          <Locale locales={locales} defaultLocale={defaultLocale} locale={locale} />
          <VisualEditorSettings shopId={shopId} shouldDisplaySettings={shouldDisplaySettings} />
        </ToolBarContainer>
      </ToolbarContainer>
    </VisualEditorTitleContainer>
  );
}
