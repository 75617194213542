import { useAppSelector } from '../../../hooks';
import { ContentType, StatefulResultsScreen } from '../types';
import { usePreviewExperience } from './usePreviewExperience';
import { useVisualEditorProvider } from '../context';

const useBasePreviewExperience = (): StatefulResultsScreen => {
  const state = useAppSelector(({ visualEditor }) => visualEditor);
  const { fetchOffers } = useVisualEditorProvider();

  const showResultsScreen = state.contentType === ContentType.ShowOffers;
  const shouldDisplayItemsCount = !state.error;
  const shouldDisplaySettings =
    state.dataFieldsToDisplay.length > 0 && state.specialDataFieldsToDisplay.length > 0;

  return {
    state,
    disableRefresh: state.loading,
    displayedOffers: state.offers,
    itemsCount: state.offers.length,
    totalItemsCount: state.total,
    onRefresh: fetchOffers,
    showResultsScreen,
    shouldDisplayItemsCount,
    shouldDisplaySettings,
  };
};

export const usePreviewScreen = (shopId: number, locale: string): StatefulResultsScreen => {
  const experience = useAppSelector(({ visualEditor }) => visualEditor.selectedExperience);
  const base = useBasePreviewExperience();
  const experienceModel = usePreviewExperience(shopId, experience, locale);

  return {
    ...base,
    ...experienceModel,
  };
};
