import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import { catalogsListPageActions } from '../../CatalogManager';
import { visualEditorActions } from '../state';
import type { Dispatch } from '../../../components-dummy/types';

interface UseGetPrimaryCatalogLocalesProps {
  shopId: number;
  appDispatch: Dispatch;
}

interface UseGetPrimaryCatalogLocalesReturnType {
  locale: string;
  locales: string[];
  defaultLocale: string;
}

export const useGetPrimaryCatalogLocales = ({
  shopId,
  appDispatch,
}: UseGetPrimaryCatalogLocalesProps): UseGetPrimaryCatalogLocalesReturnType => {
  const { locale } = useAppSelector(state => state.visualEditor);
  const catalogs = useAppSelector(state => state.catalogManager.catalogs);

  useEffect(() => {
    appDispatch(catalogsListPageActions.getCatalogs({ shopId }));
  }, [shopId]);

  const {
    validLocales = [],
    defaultLocale,
    isMultiLocale,
  } = catalogs?.find(catalog => catalog.isPrimary) || {};

  const currentLocale = isMultiLocale ? defaultLocale : undefined;

  useEffect(() => {
    appDispatch(visualEditorActions.setLocale(currentLocale));
  }, [currentLocale]);

  return { locale, locales: validLocales, defaultLocale };
};
