import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { EllipsisWithTooltip, NotificationType } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import {
  SynonymStatus,
  SynonymType,
} from 'src/services/src/service/augmented-search/synonyms/types';
import { SynonymsTableBodyRow } from '../types';
import { displayNameMap, tableColumns } from '../consts';
import { BodyRowStyled, StrikeThroughStyled } from '../SynonymsTablePage.styles';
import { formatDateToRelative } from '../../helpers';
import { StyledCellText, StyledTooltipWrapper } from '../SynonymsTablePage.styles';
import { copyToClipboard } from 'src/utils';
import { synonymsActions } from '../../SynonymsActions';
import { TableBodyRowCellStyled } from 'src/components-dummy/TableV2/TableV2.style';
import { RowActionsMenu } from './RowActionsMenu';
import { DeleteSynonymConfirmationDialog } from './DeleteSynonymConfirmationDialog';

interface SynonymsTableRowProps {
  row: Row<SynonymsTableBodyRow>;
  onDeleteSynonym: (parameters: { synonymId: string }) => void;
  dispatch: Dispatch;
  navigateToEditSynonym: (synonymId: string) => void;
}

export const SynonymsTableRow = ({
  row,
  onDeleteSynonym,
  dispatch,
  navigateToEditSynonym,
}: SynonymsTableRowProps): JSX.Element => {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onDeleteSynonymClick = useCallback(() => {
    setShowDeleteConfirmationDialog(true);
  }, [onMenuClose]);

  const onEditSynonymClick = useCallback(() => {
    navigateToEditSynonym(row.original.id);
  }, [row.original.id]);

  const onCloseDeleteDialog = useCallback(() => {
    setShowDeleteConfirmationDialog(false);
  }, []);

  const onDeleteSynonymConfirm = useCallback(() => {
    onDeleteSynonym({ synonymId: row.original.id });
    onCloseDeleteDialog();
  }, [row.original.id, onDeleteSynonym]);

  const renderCellContent = (propName: string, cellValue: any, isDeleted: boolean) => {
    if (propName === tableColumns.synonym.accessor) {
      const cellText = Array.isArray(cellValue) ? cellValue.join(', ') : cellValue;
      const content = isDeleted ? <StrikeThroughStyled>{cellText}</StrikeThroughStyled> : cellText;
      const handleCopyClick = () => {
        copyToClipboard(cellText);
        dispatch(
          synonymsActions.issueToastNotification({
            customMessage: 'Synonyms copied to clipboard',
            type: NotificationType.Success,
          })
        );
      };
      return (
        <StyledTooltipWrapper>
          <EllipsisWithTooltip
            tooltipText={
              <span style={{ cursor: 'pointer' }} onClick={handleCopyClick}>
                {cellText}
              </span>
            }
            forceShowTooltip
          >
            {content}
          </EllipsisWithTooltip>
        </StyledTooltipWrapper>
      );
    }

    if (propName === tableColumns.type.accessor) {
      return (
        <StyledCellText backgroundColorToValue={cellValue} isDeleted={row.original.isDeleted}>
          {displayNameMap[cellValue] || cellValue}
        </StyledCellText>
      );
    }

    if (propName === tableColumns.lastEdit.accessor) {
      return formatDateToRelative(cellValue);
    }

    if (propName === tableColumns.status.accessor) {
      return (
        <EllipsisWithTooltip
          tooltipText='Changes are pending and will take effect after the next index update. This may take 2-10 hours depending on the size of the index.'
          forceShowTooltip={cellValue === SynonymStatus.PendingIndex}
        >
          <StyledCellText backgroundColorToValue={cellValue} isDeleted={row.original.isDeleted}>
            {displayNameMap[cellValue] || cellValue}
          </StyledCellText>
        </EllipsisWithTooltip>
      );
    }

    if (propName === tableColumns.rowActions.accessor) {
      return (
        <RowActionsMenu
          menuAnchorElement={menuAnchorElement}
          onMenuClicked={onMenuClicked}
          onMenuClose={onMenuClose}
          onEditSynonymClick={
            row.original.type === SynonymType.Custom && !row.original.isDeleted
              ? onEditSynonymClick
              : undefined
          }
          onDeleteSynonymClick={!row.original.isDeleted ? onDeleteSynonymClick : undefined}
        />
      );
    }

    return cellValue;
  };

  return (
    <>
      {showDeleteConfirmationDialog && (
        <DeleteSynonymConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDeleteConfirm={onDeleteSynonymConfirm}
        />
      )}
      <BodyRowStyled
        {...row.getRowProps()}
        key={row.original.id}
        isDeleted={row.original.isDeleted}
      >
        {row.cells.map(cell => {
          const propName = cell.column.id;
          const cellValue = cell.value;
          const isDeleted = cell.row.original.isDeleted;
          const cellContent = renderCellContent(propName, cellValue, isDeleted);
          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.phrase}-${propName}`}
            >
              {cellContent}
            </TableBodyRowCellStyled>
          );
        })}
      </BodyRowStyled>
    </>
  );
};
