import React from 'react';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { PaginationWithItemsPerPage } from 'src/components-dummy';
import { SynonymsTableRow } from './SynonymsTableRow';
import { SynonymsTableHeaderColumns } from './SynonymsTableHeaderColumns';
import { PaginationContainer, TableWrapperStyled } from '../SynonymsTablePage.styles';
import { SynonymsTableHeadRow } from '../types';
import { Dispatch } from 'src/components-bl/types';
import { Synonym } from 'src/services';
import { PaginationArguments } from 'src/services/src/service/merchandising-rules/types';
import { SynonymLanguage } from '../../../../../services/src/service/augmented-search/synonyms/types';
import { UploadNewFileButton } from '../../SynonymImport/UploadNewFileButton';
import { RoutedComponentProps } from '../../../../../app-routes';
import { SynonymsFiltersState } from './SynonymsFilters';

interface SynonymsListProps extends RoutedComponentProps {
  columns: SynonymsTableHeadRow[];
  language: SynonymLanguage;
  synonyms: Synonym[];
  shopId: number | undefined;
  dispatch: Dispatch;
  paginationState: PaginationArguments;
  totalSynonymsCount: number;
  filtersValue: SynonymsFiltersState;
  onPaginationChange: (value: PaginationArguments) => void;
  onDeleteSynonym: (parameters: { synonymId: string }) => void;
  navigateToEditSynonym: (synonymId: string) => void;
}

export const SynonymsList: React.FC<SynonymsListProps> = ({
  dispatch,
  language,
  shopId,
  columns,
  synonyms,
  paginationState,
  totalSynonymsCount,
  permittedRouteMap,
  onPaginationChange,
  onDeleteSynonym,
  navigateToEditSynonym,
  filtersValue,
}) => {
  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<any>, 'rows' | 'prepareRow'>) => {
    if (!synonyms.length) {
      return (
        <UploadNewFileButton
          language={language}
          shopId={shopId}
          dispatch={dispatch}
          permittedRouteMap={permittedRouteMap}
          hasUploadNewFileButton
          filtersValue={filtersValue}
        />
      );
    }
    return rows.map(row => {
      prepareRow(row);
      return (
        <SynonymsTableRow
          key={row.original.id}
          row={row}
          dispatch={dispatch}
          onDeleteSynonym={onDeleteSynonym}
          navigateToEditSynonym={navigateToEditSynonym}
        />
      );
    });
  };

  return (
    <>
      <TableWrapperStyled>
        <TableV2
          options={{ columns, data: synonyms }}
          customOptions={{
            shouldUseFlexLayout: true,
            shouldUseResizeColumns: true,
          }}
        >
          {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
            const headerGroup = headerGroups[0];
            return (
              <>
                <TableV2.Head isSticky {...headerGroup.getHeaderGroupProps()}>
                  <SynonymsTableHeaderColumns headers={headerGroup.headers} />
                </TableV2.Head>
                <TableV2.Body {...getTableBodyProps()}>
                  {renderBody({ rows, prepareRow })}
                </TableV2.Body>
              </>
            );
          }}
        </TableV2>
      </TableWrapperStyled>
      <PaginationContainer>
        <PaginationWithItemsPerPage
          skip={paginationState.skip}
          limit={paginationState.limit}
          totalItemsCount={totalSynonymsCount}
          pagesToShow={5}
          onPaginationChange={onPaginationChange}
        />
      </PaginationContainer>
    </>
  );
};
