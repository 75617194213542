import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const CatalogLocaleSelectionTitleStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px !important;
`;

export const CatalogLocaleSelectionContainerStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

/**
 * Page
 */
export const PageStyled = styled(Page)`
  height: 100%;
  position: relative;

  .syte-page-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
  }

  .syte-page-sub-title {
    a {
      color: ${props => props.theme.palette.custom['primary-main']};
    }
  }

  .syte-page-content {
    flex-direction: column;
    padding-top: unset;
  }

  background: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const LanguageListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  width: fit-content;
  padding: 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const LanguagesTitleStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px !important;
`;
