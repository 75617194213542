import React from 'react';
import { BubbleContainerStyled, BubbleStyled } from './SneakPick.styles';
import { AvailableIcons, Icon } from 'src/components-dummy';

export const SneakPeak = ({ children }: { children: React.ReactChild }) => {
  return (
    <BubbleContainerStyled>
      <BubbleStyled>{children}</BubbleStyled>
      <Icon name={AvailableIcons.TriangleSneakPeak} />
    </BubbleContainerStyled>
  );
};
