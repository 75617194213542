import styled from '@emotion/styled';
import { Menu, SearchInput, Typography } from '../../../../components-dummy';

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    width: fit-content;
  }
`;

export const MenuContent = styled.div``;

export const SearchInputStyled = styled(SearchInput)`
  margin: 10px 16px;
  background-color: #f2f2f2;
  border-radius: 8px;
  .input-base-wrapper {
    border: none;
  }
`;

export const SubTitle = styled(Typography)`
  color: #afafaf;
`;
