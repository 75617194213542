import React, { MouseEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  MenuItem,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { Button } from './Button';
import { useFilterItems } from '../../../../hooks';
import { getLocaleDisplayNameWithEmoji } from './get-locale-display-name-with-emoji';
import { visualEditorActions } from '../../state';
import { MenuContent, MenuStyled, SearchInputStyled, SubTitle } from './Locale.styles';

interface LocaleProps {
  locales: string[];
  defaultLocale: string;
  locale: string;
}

export const Locale = ({ locales, defaultLocale, locale }: LocaleProps) => {
  const dispatch = useDispatch();

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(menuAnchorElement);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onMenuItemClick = useCallback(
    (selectedLocale: string) => () => {
      dispatch(visualEditorActions.setLocale(selectedLocale));
      onMenuClose();
      setSearchInputValue('');
    },
    [dispatch]
  );

  const localeOptions = locales
    .map(l => {
      const { displayName, locale: matchedLocale } = getLocaleDisplayNameWithEmoji(l);
      return {
        value: matchedLocale,
        text: displayName,
        isPrimary: matchedLocale === defaultLocale,
      };
    })
    .sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));

  const { filteredItems } = useFilterItems({
    items: localeOptions,
    searchableFields: ['value', 'text'],
    idField: 'value',
    filterText: searchInputValue,
  });

  return (
    <>
      <Button locales={locales} locale={locale} onMenuClicked={onMenuClicked} />
      <MenuStyled open={isOpen} anchorEl={menuAnchorElement} onClose={onMenuClose}>
        <MenuContent>
          <SearchInputStyled
            value={searchInputValue}
            onChange={onSearchInputChange}
            placeholder='Search...'
          />
          {filteredItems.map(({ value, text, isPrimary }) => (
            <MenuItem key={value} onClick={onMenuItemClick(value)}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                {text}
              </Typography>
              {isPrimary && (
                <SubTitle type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  {'\u00A0'}| Primary Locale
                </SubTitle>
              )}
            </MenuItem>
          ))}
        </MenuContent>
      </MenuStyled>
    </>
  );
};
