import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useVisualEditorProvider } from '../context';
import { CollectionItemsPayload, ResultsScreen } from '../types';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { ShopFeature } from '../../../app-types';
import { visualEditorFetchOffersActions } from '../state';
import { useGetDataFieldsToDisplay } from './useGetDataFieldsToDisplay';

export const usePreviewCollections = (shopId: number, locale: string): Partial<ResultsScreen> => {
  const { selectedOffer, ...previewState } = useAppSelector(({ visualEditor }) => visualEditor);
  const { offers, collection, error, loading, total, selectedExperience } = previewState;
  const [{ dataCollectionId: collectionId }, { sku }] = [collection || {}, selectedOffer || {}];
  const [showLoadingResults, setShowLoadingResults] = useState<boolean>(false);
  const { dispatchOffersThunk } = useVisualEditorProvider();

  const { fields } = useGetDataFieldsToDisplay({ shopId });

  const fetchCollectionItems = useCallback((payload: CollectionItemsPayload) => {
    setShowLoadingResults(!payload.from);
    dispatchOffersThunk(visualEditorFetchOffersActions.getCollectionItems, payload)
      .unwrap()
      .finally(() => setShowLoadingResults(false));
  }, []);

  const fetchOffers = useCallback(() => {
    if (!collectionId) return;
    fetchCollectionItems({
      shopId,
      collectionId,
      sku,
      fields,
      colorVariantFields: fields,
      sizeVariantFields: fields,
      locale,
    });
  }, [shopId, collectionId, sku, fields, locale]);

  const onLoadMore = useCallback(() => {
    if (!collectionId || !offers.length) return;
    fetchCollectionItems({
      shopId,
      collectionId,
      sku,
      from: offers.length,
      fields,
      colorVariantFields: fields,
      sizeVariantFields: fields,
      locale,
    });
  }, [shopId, collectionId, sku, offers, fields, locale]);

  const hasNextPage = !error && !!offers.length && offers.length < total;

  const [sentryRef, { rootRef, isVisible }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: error,
    delayInMs: 300,
  });

  const canExecuteEffects = selectedExperience === ShopFeature.Collections;
  const displayedOffers = showLoadingResults ? [] : offers;
  const showLoader = hasNextPage && !showLoadingResults;
  const disableRefresh = loading || isVisible;

  const infiniteScroll = useMemo(
    () => ({ sentryRef, rootRef, showLoader }),
    [sentryRef, rootRef, showLoader]
  );

  useEffect(() => {
    if (!canExecuteEffects) return;
    if (!collection && !selectedOffer) return;
    fetchOffers();
  }, [collection, selectedOffer]);

  return {
    displayedOffers,
    onRefresh: fetchOffers,
    disableRefresh,
    infiniteScroll,
  };
};
