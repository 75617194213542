import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AutoCompleteOption, useDebounce } from '../../../../components-dummy';
import type { CategoryFilterRule } from '../../types/category-filter-rule';
import { useAppSelector } from '../../../../hooks';
import { visualEditorActions } from '../../state';

interface Props {
  shopId: number;
  categoryFilterRule: CategoryFilterRule;
  changeCategory: (category: string) => void;
}

interface UseFetchAutoSuggestResultsReturnType {
  options: AutoCompleteOption[];
  onChange: (value: string) => void;
  onSelectOption: (selectedOption: any) => void;
  loading: boolean;
}

export const useFetchAutoSuggestResults = ({
  shopId,
  categoryFilterRule,
  changeCategory,
}: Props): UseFetchAutoSuggestResultsReturnType => {
  const { autocompleteOptions, isSearchLoading, locale } = useAppSelector(
    state => state.visualEditor
  );

  const dispatch = useDispatch();

  const field = useMemo(() => categoryFilterRule?.field || 'categories', [categoryFilterRule]);

  const fetchDataFieldOptions = useCallback(
    (searchTerm?: string, dataField?: string) => {
      dispatch(visualEditorActions.getSearchResults({ shopId, searchTerm, dataField, locale }));
    },
    [shopId, field, locale]
  );

  const fetchDataFieldOptionsDebounced = useDebounce(fetchDataFieldOptions, 300);

  const onChange = useCallback(
    (searchValue: string): void => {
      changeCategory(searchValue);
      fetchDataFieldOptionsDebounced(searchValue, field);
    },
    [changeCategory, fetchDataFieldOptionsDebounced, field]
  );

  useEffect(() => {
    fetchDataFieldOptions('', field);
  }, [field]);

  return {
    options: autocompleteOptions,
    onChange,
    onSelectOption: onChange,
    loading: isSearchLoading,
  };
};
