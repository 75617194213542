import { useEffect, useState } from 'react';
import { useVisualEditorProvider } from '../../context';
import { useFetchPLPOffers } from '../../hooks/useFetchPLPOffers';
import { visualEditorActions } from '../../state';
import { ContentType } from '../../types';

export const useUpdateCategoryPage = (shopId: number, categoryPage: string, locale: string) => {
  const { updateCategoryValue } = useFetchPLPOffers(shopId, locale);
  const { appDispatch } = useVisualEditorProvider();

  const [category, setCategory] = useState<string>(categoryPage || '');

  useEffect(() => {
    if (!category.length) {
      appDispatch(visualEditorActions.setContentType(ContentType.SelectExperience));
      return;
    }
    updateCategoryValue(category);
    setCategory(category);
  }, [category]);
};
