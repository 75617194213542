import { useEffect, useState } from 'react';
import { useVisualEditorProvider } from '../../context';
import { visualEditorActions } from '../../state';
import { ContentType } from '../../types';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { useFetchPLPOffers } from '../../hooks/useFetchPLPOffers';
import { PreviewFeature } from '../../../../services';
import { PermittedRouteMap } from '../../../../app-routes';
import { useSyncParamsWithState } from '../../helpers/use-sync-params-with-state';
import { useAddParamsToRoute } from '../../hooks/useAddParamsToRoute';

interface UseBrowsePlpSettingsProps {
  shopId: number;
  stateController: StateController<string>;
  permittedRouteMap: PermittedRouteMap;
  locale: string;
  selectedExperience?: PreviewFeature;
}

export const useBrowsePlpSettings = ({
  shopId,
  stateController,
  permittedRouteMap,
  locale,
  selectedExperience,
}: UseBrowsePlpSettingsProps) => {
  const { updateCategoryValue } = useFetchPLPOffers(shopId, locale);
  const { appDispatch } = useVisualEditorProvider();
  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });

  const { value: initialValue, setValue, shouldReset, setResetFlag } = stateController;

  const [category, setCategory] = useState<string>(initialValue || '');

  useSyncParamsWithState({ paramName: 'categoryPage', setState: setCategory });

  useEffect(() => {
    if (!category.length) {
      appDispatch(visualEditorActions.setContentType(ContentType.SelectExperience));
      return;
    }
    updateCategoryValue(category);
    if (shouldReset) {
      setCategory('');
      setResetFlag(false);
      addParamsToRoute({ shopperExperience: selectedExperience });
      return;
    }
    setCategory(category);
    addParamsToRoute({ shopperExperience: selectedExperience, categoryPage: category });
  }, [category, selectedExperience, shouldReset, locale]);

  useEffect(() => {
    setValue(category);
  }, [category]);

  return {
    category,
    changeCategory: setCategory,
  };
};
