import React from 'react';
import { PreviewMerchRule } from '../../types';
import { StateController } from './ShopperExperience/useStateControllerRef';
import { PermittedRouteMap } from '../../../../app-routes';
import { EditorTitle } from '../EditorTitle';
import { RuleDescription } from './RuleDescription';
import { Trigger } from './Trigger';
import { Strategy } from './Strategy';
import { SettingsForm } from '../Form';
import { useGetSelectedMerchRule } from './useGetSelectedMerchRule';
import { Line } from './SideMenu.styles';

interface Props {
  setForInternalUseOnly: boolean;
  onClose: VoidFunction;
  selectedRule: PreviewMerchRule | null;
  shopId: number;
  stateController: StateController<any>;
  permittedRouteMap: PermittedRouteMap;
  locale: string;
}

export const SideMenuEdit = ({
  setForInternalUseOnly,
  onClose,
  selectedRule,
  shopId,
  stateController,
  permittedRouteMap,
  locale,
}: Props): JSX.Element | null => {
  const { selectedMerchRule } = useGetSelectedMerchRule({ selectedRule });

  if (!selectedMerchRule) {
    return null;
  }
  return (
    <>
      <EditorTitle
        onClose={onClose}
        setForInternalUseOnly={setForInternalUseOnly}
        title='Visual Editor'
        backButtonText='Back to Preview'
      />
      <Line />
      <SettingsForm>
        <RuleDescription selectedRule={selectedMerchRule} />
        <Trigger
          selectedRule={selectedMerchRule}
          shopId={shopId}
          browsePLPValue={stateController.value}
          locale={locale}
        />
        <Strategy
          selectedRule={selectedMerchRule}
          shopId={shopId}
          permittedRouteMap={permittedRouteMap}
        />
      </SettingsForm>
    </>
  );
};
