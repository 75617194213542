import styled from '@emotion/styled';

export const BubbleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    path {
      fill: #d0e3fd;
    }
  }
`;

export const BubbleStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: #d0e3fd;
  padding: 12px;
  border-radius: 4px 0 0 4px;
`;

export const Triangle1Styled = styled.div`
  position: absolute;
  top: -2px;
  right: -56px;
  height: 0;
  border-left: 28px solid white;
  border-right: 28px solid transparent;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-left-color: #0173ff;
  border-radius: 0px;
`;

export const Triangle2Styled = styled.div`
  position: absolute;
  width: 0px;
  height: 0;
  top: -1px;
  right: -52px;
  border-left: 27px solid white;
  border-right: 27px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 6px;
`;
