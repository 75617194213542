import { DEFAULT_LOCALE } from '../../consts/default-locale';

export const getLocaleDisplayNameWithEmoji = (
  locale: string
): { displayName: string; locale: string } => {
  if (!locale) {
    return { displayName: '🌐', locale: DEFAULT_LOCALE };
  }
  const [language, region] = locale.split('_');

  try {
    const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    const languageName = displayNames.of(language) || language;
    const regionName = regionNames.of(region) || region;

    const emojiFlag = region.match(/^[A-Z]{2}$/i)
      ? region
          .toUpperCase()
          .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0)))
      : '🌐';

    const displayName = `${emojiFlag} ${languageName} (${regionName})`;

    return { displayName, locale };
  } catch (error) {
    return { displayName: `🌐 ${language} (${region})`, locale };
  }
};
