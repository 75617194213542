/* eslint-disable no-param-reassign */
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  ContentType,
  visualEditorActions,
  visualEditorFetchOffersActions,
} from 'src/components-bl';
import { VisualEditorReducerState as State } from '../app-state-types';
import { ShopFeature } from '../app-types';
import { DEFAULT_ITEMS_COUNT_PER_ROW } from '../components-bl/VisualEditor/consts/default-items-count-per-row';

const initialState: State = {
  offers: [],
  autocompleteOptions: [],
  isSearchLoading: false,
  total: 0,
  merchandisingRules: {
    global: null,
    currentCollection: null,
    debug: null,
    selectedRule: null,
    loading: false,
  },
  originalRequest: null,
  experiences: null,
  collection: null,
  contentType: ContentType.SelectExperience,
  selectedOffer: null,
  selectedExperience: null,
  dataFieldsToDisplay: [],
  specialDataFieldsToDisplay: [],
  itemsCountPerRow: DEFAULT_ITEMS_COUNT_PER_ROW,
  uniqueByField: undefined,
  categoryFilterRule: undefined,
  product: {
    data: null,
    loading: false,
    error: false,
  },
  locale: undefined,
  loading: false,
  error: false,
};

export const visualEditorReducer = createReducer(initialState, builder => {
  builder.addCase(visualEditorActions.resetState, () => initialState);

  builder.addCase(visualEditorActions.resetPartialState, (state: State) => {
    state.contentType = ContentType.SelectExperience;
    state.selectedOffer = null;
    state.merchandisingRules.currentCollection = null;
    state.merchandisingRules.global = null;
    state.collection = null;
    state.offers = [];
  });

  builder.addCase(visualEditorActions.changeExperience, (state: State, action) => {
    state.selectedExperience = action.payload;
  });

  builder.addCase(visualEditorActions.setCollection, (state, action) => {
    state.collection = action.payload;
  });

  builder.addCase(visualEditorActions.getPreviewExperiences.fulfilled, (state: State, action) => {
    state.experiences = action.payload;
  });

  builder.addCase(visualEditorActions.setContentType, (state, action) => {
    state.contentType = action.payload;
  });

  builder.addCase(visualEditorActions.setSelectedOffer, (state, action) => {
    state.selectedOffer = action.payload;
  });

  builder.addCase(visualEditorActions.findAndUpdateRule, (state, action) => {
    const allMerchRules = [
      ...(state.merchandisingRules.global || []),
      ...(state.merchandisingRules.currentCollection || []),
    ];
    const ruleToUpdate = allMerchRules.find(rule => rule.id === action.payload.id);
    if (ruleToUpdate) {
      Object.assign(ruleToUpdate, action.payload);
    }
  });

  builder.addCase(visualEditorActions.updateRule, (state, action) => {
    const allMerchRules = [
      ...(state.merchandisingRules.global || []),
      ...(state.merchandisingRules.currentCollection || []),
    ];
    const ruleToUpdate = allMerchRules.find(rule => rule.id === action.payload.id);
    if (ruleToUpdate) {
      Object.assign(ruleToUpdate, action.payload);
    }
  });

  builder.addCase(visualEditorActions.setSelectedRule, (state, action) => {
    state.merchandisingRules.selectedRule = action.payload;
  });

  builder.addCase(visualEditorActions.setAutocompleteOptions, (state, action) => {
    state.autocompleteOptions = action.payload;
  });

  builder.addCase(visualEditorActions.setIsSearchLoading, (state, action) => {
    state.isSearchLoading = action.payload;
  });

  builder.addCase(visualEditorActions.addNewRule, (state, action) => {
    if (state.selectedExperience === ShopFeature.Collections) {
      state.merchandisingRules.currentCollection = [
        ...(state.merchandisingRules.currentCollection || []),
        action.payload,
      ];
      return;
    }
    state.merchandisingRules.global = [...(state.merchandisingRules.global || []), action.payload];
  });

  builder.addCase(visualEditorActions.getMerchRules.pending, state => {
    state.merchandisingRules.global = null;
  });

  builder.addCase(visualEditorActions.getMerchRules.fulfilled, (state, action) => {
    state.merchandisingRules.global = action.payload;
  });

  builder.addCase(visualEditorActions.getCollectionMerchRules.pending, state => {
    state.merchandisingRules.currentCollection = null;
  });

  builder.addCase(visualEditorActions.getCollectionMerchRules.fulfilled, (state, action) => {
    state.merchandisingRules.currentCollection = action.payload;
  });

  builder.addCase(visualEditorActions.getVisualEditorSettings.fulfilled, (state, action) => {
    state.dataFieldsToDisplay = action.payload.dataFieldsToDisplay;
    state.specialDataFieldsToDisplay = action.payload.specialDataFieldsToDisplay;
    state.uniqueByField = action.payload.uniqueByField;
    state.itemsCountPerRow = action.payload.itemsCountPerRow;
  });

  builder.addCase(visualEditorActions.updateVisualEditorSettings.fulfilled, (state, action) => {
    state.dataFieldsToDisplay = action.payload.dataFieldsToDisplay;
    state.specialDataFieldsToDisplay = action.payload.specialDataFieldsToDisplay;
    state.itemsCountPerRow = action.payload.itemsCountPerRow;
  });

  builder.addCase(visualEditorActions.getCategoryFilterRule.fulfilled, (state, action) => {
    state.categoryFilterRule = action.payload;
  });

  builder.addCase(visualEditorActions.updateCategoryFilterRule.fulfilled, (state, action) => {
    state.categoryFilterRule = action.payload;
  });

  builder.addCase(visualEditorActions.getProduct.fulfilled, (state: State, action) => {
    state.product = { data: action.payload, loading: false, error: false };
  });

  builder.addCase(visualEditorActions.getProduct.pending, (state: State) => {
    state.product = { data: null, loading: true, error: false };
  });

  builder.addCase(visualEditorActions.getProduct.rejected, (state: State) => {
    state.product = { data: null, loading: false, error: true };
  });

  builder.addCase(visualEditorActions.setLocale, (state, action) => {
    state.locale = action.payload;
  });

  builder.addMatcher(
    isAnyOf(
      visualEditorActions.getMerchRules.pending,
      visualEditorActions.getCollectionMerchRules.pending
    ),
    state => {
      state.merchandisingRules.loading = true;
    }
  );

  builder.addMatcher(
    isAnyOf(
      visualEditorActions.getMerchRules.fulfilled,
      visualEditorActions.getMerchRules.rejected,
      visualEditorActions.getCollectionMerchRules.fulfilled,
      visualEditorActions.getCollectionMerchRules.rejected
    ),
    state => {
      state.merchandisingRules.loading = false;
    }
  );

  builder.addMatcher(
    isAnyOf(
      visualEditorFetchOffersActions.getCollectionItems.fulfilled,
      visualEditorFetchOffersActions.getSimilarItems.fulfilled,
      visualEditorFetchOffersActions.getShopTheLookItems.fulfilled,
      visualEditorFetchOffersActions.getDiscoveryButtonItems.fulfilled,
      visualEditorFetchOffersActions.getBrowsePLPItems.fulfilled,
      visualEditorFetchOffersActions.getTextSearchItems.fulfilled
    ),
    (state, action) => {
      state.offers = action.payload.offers;
      state.total = action.payload.total;
      state.originalRequest = action.payload.originalRequest;
      state.merchandisingRules.debug = action.payload.merchRulesDebugData;
      state.contentType = action.payload.offers.length
        ? ContentType.ShowOffers
        : ContentType.NoResults;
    }
  );

  builder.addMatcher(
    isAnyOf(
      visualEditorFetchOffersActions.getSimilarItems.pending,
      visualEditorFetchOffersActions.getCollectionItems.pending,
      visualEditorFetchOffersActions.getShopTheLookItems.pending,
      visualEditorFetchOffersActions.getDiscoveryButtonItems.pending,
      visualEditorFetchOffersActions.getBrowsePLPItems.pending,
      visualEditorFetchOffersActions.getTextSearchItems.pending
    ),
    state => {
      state.loading = true;
      state.error = false;
    }
  );

  builder.addMatcher(
    isAnyOf(
      visualEditorFetchOffersActions.getSimilarItems.fulfilled,
      visualEditorFetchOffersActions.getCollectionItems.fulfilled,
      visualEditorFetchOffersActions.getShopTheLookItems.fulfilled,
      visualEditorFetchOffersActions.getDiscoveryButtonItems.fulfilled,
      visualEditorFetchOffersActions.getBrowsePLPItems.fulfilled,
      visualEditorFetchOffersActions.getTextSearchItems.fulfilled
    ),
    state => {
      state.loading = false;
      state.error = false;
    }
  );

  builder.addMatcher(
    isAnyOf(
      visualEditorFetchOffersActions.getSimilarItems.rejected,
      visualEditorFetchOffersActions.getCollectionItems.rejected,
      visualEditorFetchOffersActions.getShopTheLookItems.rejected,
      visualEditorFetchOffersActions.getDiscoveryButtonItems.rejected,
      visualEditorFetchOffersActions.getBrowsePLPItems.rejected,
      visualEditorFetchOffersActions.getTextSearchItems.rejected
    ),
    state => {
      state.offers = [];
      state.contentType = ContentType.NoResults;
      state.loading = false;
      state.error = true;
    }
  );
});
