import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { CatalogUnavailableAlert } from './CatalogUnavailableAlert';
import { RowActionsMenu } from './RowActionsMenu';
import { tableColumns } from './tableColumns';
import { ICatalogTableBodyRow } from './types';
import { DeleteCatalogConfirmationDialog } from './DeleteCatalogConfirmationDialog';
import { CatalogNameCellStyled, TableBodyRowCellStyled } from './CatalogTableRow.styles';

interface CatalogTableRowProps {
  row: Row<ICatalogTableBodyRow>;
  onSetCatalogAsPrimary: (catalogId: string) => void;
  onCatalogDelete: (catalogId: string) => void;
  navigateToEditCatalog: (catalogId: string) => void;
  navigateToFeedUploadLog: (catalogId: string) => void;
  navigateToViewCatalog: (catalogId: string) => void;
  onCatalogReconnect: (catalogId: string) => void;
  lastUpdated: string;
  readOnly: boolean;
}

export const CatalogTableRow = ({
  row,
  onSetCatalogAsPrimary,
  onCatalogDelete,
  navigateToEditCatalog,
  navigateToFeedUploadLog,
  navigateToViewCatalog,
  onCatalogReconnect,
  lastUpdated,
  readOnly,
}: CatalogTableRowProps): JSX.Element => {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const isMultiLocale = row.original.originalCatalog.isMultiLocale;

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onSetPrimaryCatalogClick = row.original.canBeSetAsPrimary
    ? () => onSetCatalogAsPrimary(row.original.id)
    : undefined;

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onEditCatalogClick = () => {
    navigateToEditCatalog(row.original.id);
  };

  const onFeedUploadsLogClick = () => {
    navigateToFeedUploadLog(row.original.id);
  };
  const onViewCatalogClick = () => {
    navigateToViewCatalog(row.original.id);
  };

  const onCatalogDeleteConfirm = () => {
    onCatalogDelete(row.original.id);
    setShowDeleteConfirmationDialog(false);
  };

  const onCatalogReconnectClick = () => {
    onCatalogReconnect(row.original.id);
  };

  const onOpenDeleteDialog = useCallback(() => {
    onMenuClose();
    setShowDeleteConfirmationDialog(true);
  }, [onMenuClose]);

  const onCloseDeleteDialog = useCallback(() => {
    onMenuClose();
    setShowDeleteConfirmationDialog(false);
  }, []);

  const renderCell = ({
    cell,
    propName,
  }: {
    propName: keyof ICatalogTableBodyRow;
    cell: TableV2InstanceProps<ICatalogTableBodyRow>['rows'][0]['cells'][0];
  }) => {
    if (propName === tableColumns.shouldShowAlert.accessor) {
      return cell.value ? <CatalogUnavailableAlert /> : null;
    }

    if (propName === tableColumns.name.accessor) {
      return (
        <CatalogNameCellStyled onClick={onViewCatalogClick} isClickable key={`${Math.random()}`}>
          {cell.value}
        </CatalogNameCellStyled>
      );
    }

    return <TableV2.BodyRowCellText key={`${Math.random()}`}>{cell.value}</TableV2.BodyRowCellText>;
  };

  return (
    <>
      {showDeleteConfirmationDialog && (
        <DeleteCatalogConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDelete={onCatalogDeleteConfirm}
        />
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id} className='catalog-table-row'>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={`${cell.column.id}-${cell.value}`}
              className='catalog-table-cell'
              data-id={`${row.original.originalCatalog.name}-${propName}`}
              isResizable
            >
              {propName === tableColumns.rowActions.accessor ? (
                <RowActionsMenu
                  menuAnchorElement={menuAnchorElement}
                  onMenuClicked={onMenuClicked}
                  onMenuClose={onMenuClose}
                  onSetPrimaryCatalogClick={onSetPrimaryCatalogClick}
                  onCatalogDeleteClick={onOpenDeleteDialog}
                  onEditCatalogClick={onEditCatalogClick}
                  onViewCatalogClick={isMultiLocale ? undefined : onViewCatalogClick}
                  onFeedUploadsLogClick={lastUpdated ? onFeedUploadsLogClick : undefined}
                  onCatalogReconnect={onCatalogReconnectClick}
                  readOnly={readOnly}
                />
              ) : (
                renderCell({ cell, propName: propName as keyof ICatalogTableBodyRow })
              )}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
